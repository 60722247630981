.container {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.item {
  flex-basis: 280px;
  margin: 0 20px 20px 0;
}

.selectableBoxInfo {
  padding-top: 15px;
  font-weight: 300;
}

@supports (display: grid) {
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 20px;
  }

  .item {
    margin: 0;
  }
}
