.categoryList {
  padding: 16px 0;
}

.categoryItem {
  margin-bottom: 8px;
  border: 2px solid #f2f2f2;
}

.categoryItemDetails {
  padding: 12px;
  border-bottom: 1px solid #ededed;
}

.categoryDescription {
  margin: 0;
}

@supports (display: grid) {
  @media screen and (min-width: 700px) {
    .categoryItem {
      display: grid;
      grid-template-columns: 300px 1fr;
      grid-gap: 8px;
      padding: 0 8px;
    }

    .categoryItemDetails {
      border-bottom: none;
    }
  }
}

.categoryItemSubDetails {
  padding: 12px;
}
