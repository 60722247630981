.displayBlock {
  display: block;
  float: left;
  width: 100%;
}
.clearBoth {
  clear: both;
}
.displayInline {
  display: inline;
}
.alignRight {
  float: right;
}
.alignTextArea {
  margin-top: 50px;
  max-height: 200px;
}