.isDisabled {
  cursor: default;
  text-decoration: none;
}
.sortContainer {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.activeBox {
  outline: 2px solid #75bee9;
}

.sortableItem {
  cursor: grab;
  flex-basis: 280px;
  margin-right: 20px;
  padding: 12px 24px;
}

.sortableItem:focus > div {
  position: relative;
  outline: 4px solid #75bee9;
}

.sortableItem:focus::after {
  content: '[Space] to select/confirm, [ArrowLeft/ArrowRight] to sort';
  display: block;
  padding: 8px;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  width: 100%;
  top: -1.5em;
}

@supports (display: grid) {
  .sortContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 20px;
  }

  .sortableItem {
    margin: 0;
  }
}
