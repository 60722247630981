.lossTypeList {
  list-style-type: none;
  padding: 8px 4px 0;
}

.lossTypeItem {
  margin-bottom: 8px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* @media screen and (min-width: 500px) {
  .lossTypeItem {
  }
} */

.lossTypeItem:hover,
.lossTypeItem:focus-within {
  outline: 3px solid #f4f4f4;
}

.lossTypeItem:last-child {
  margin-bottom: 0;
}

.itemTitle {
  flex: 1;
}

.itemSubTitle {
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.icon {
  fill: #c6c6c6;
}

.btnContainer {
  text-align: right;
}
