.root {
  position: relative;
  margin-bottom: 0px;
}

.menu {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0 2px 2px rgba(70, 70, 70, 0.5);
}

.itemList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.item {
  padding: 8px 16px;
}

.highlightedItem {
  background-color: #f2f2f2;
}

.selectedItem {
  font-weight: 500;
}

.disabledItem {
  color: #797979;
}

.indentItem {
  padding-left: 48px;
}
